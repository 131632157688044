import { Notify  } from 'vant';
import {clearAll, localStorageGet, localStorageSave} from "@/libs/util";

// 把下拉菜单放入表单莫模板中
export const setSelectItems = function (formInfo, rowName, selectItems) {
  for (var i = 0; i < formInfo.length; i++) {
    if (formInfo[i]['value'] == rowName) {
      formInfo[i]['addtion']['selectItems'] = selectItems
    }
  }
}

export const setSearchSelectItems = function (searchTable, rowName, selectItems) {
  for (var i = 0; i < searchTable.length; i++) {
    let inList = searchTable[i]
    for (let j = 0; j < inList.length; j++) {
      if (inList[j]['value'] == rowName) {
        inList[j]['items'] = selectItems
      }
    }
  }
}

// 判断列表是否包含某个对象
export const arrayContain = function (array, obj) {
  for (var i = 0; i < array.length; i++) {
    var arrayItem = array[i]
    if (arrayItem == obj) {
      return true
    }
  }
  return false
}

/**
 * 把数据库查出的信息放入列表中
 * @param data
 * @param form
 * @param trans
 */
export const saveDataToForm = function (data, form, trans) {
  for (var i = 0, keys = Object.keys(data); i < keys.length; i++) {
    var key = keys[i]
    var newKey = key
    if (trans) {
      if (trans[key]) {
        newKey = trans[key]
      }
    }

    if (typeof data[key] === 'number') {
      form[newKey] = data[key] + ''
    } else {
      form[newKey] = data[key]
    }
  }
}

/**
 * 处理拿到respons后的操作
 * @type {{dealResponse: responseUtil.dealResponse, isResponseSuccess(*): boolean}}
 */
export const responseUtil = {
  /**
   * 最原始的处理response的操作
   * @param that
   * @param response
   * @param success
   * @param error
   */
  dealResponseNative: function (that, response, success, error) {
    if (this.isResponseSuccess(response)) {
      if (success) {
        success()
      }
    } else {
      if (error) {
        error()
      }
    }
  },
  /**
   * 处理response,错误后弹出异常信息
   * @param that
   * @param response
   * @param success
   */
  dealResponse (that, response, success, config) {
    // eslint-disable-next-line no-unused-vars
    var title = '提示'
    var content = response.message
    if (config) {
      if (config['title']) title = config['title']
      if (config['content']) content = config['content']
    }
    var error = () => {
      Notify({ type: 'danger', message: content });
    }
    this.dealResponseNative(that, response, success, error)
  },
  /**
   * 判断是response是否成功
   * @param response
   * @returns {boolean}
   */
  isResponseSuccess (response) {
    if (response.code == 200) {
      return true
    }
    return false
  },


  alertErrMsg (msg) {
    // 警告通知
    Notify({ type: 'warning', message: msg });
  },

  alertMsg (msg) {
    // 主要通知
    Notify({ type: 'primary', message: msg });
  },
  alertOK () {
    // 成功通知
    Notify({ type: 'success', message: '操作成功' });
  }
}

/**
 * 获取用户的user_id
 * @returns {string}
 */

export const getStaffId = function () {
  return localStorageGet('staff_id') ? localStorageGet('staff_id') : '-1'
}

export const globaluserId = function () {
    return localStorageSave('user_id') ? localStorageSave('user_id') : '-1'
}

export const saveListInfo = (that, info) => {
  that.tableData = info.child
  that.page.pageCount = info.pageCount
  that.page.totalPage = info.totalpage
}

export const formUtil = {
  addFormItem (that, beforeItemName, addItem) {
    for (var i = 0; i < that.formInfo.length; i++) {
      var obj = that.formInfo[i]
      if (obj.value == beforeItemName) {
        let beforePart = that.formInfo.slice(0, i + 1)
        let endPart = that.formInfo.slice(i + 1, that.formInfo.length)

        that.formInfo = [...beforePart, addItem, ...endPart]

        break
      }
    }
  },

  removeFormItem (that, itemName) {
    for (var i = 0; i < that.formInfo.length; i++) {
      var obj = that.formInfo[i]
      if (obj.value == itemName) {
        that.formInfo.splice(i, 1)
      }
    }
  }

}


// 把list分组
export const list2groupList = function (list, rowCount) {
  var groupList = []//searchTable
  var tempList = []


  for (var i = 0; i < list.length; i++) {
    if (i % rowCount == 0) {
      tempList = []
      groupList.push(tempList)
    }
    var item = list[i]
    tempList.push(item)
  }

  return groupList
}

// 把list分组
export const groupList2list = function (list) {
  var result = []
  for(let i = 0 ; i < list.length ; i++){
    result = [...(list[i]),...result]
  }
  return result
}
/**
 * @description 格式化金额
 * @param number：要格式化的数字
 * @param decimals：保留几位小数 默认0位
 * @param decPoint：小数点符号 默认.
 * @param thousandsSep：千分位符号 默认为,
 */
export const money = (number) => {
  if(!number) return "0.00"
  var num = "";
  if(number){
    num = (number+ '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g,'$1,');
    var xsd=num.toString().split(".");
    if(xsd.length==1){
      num=num.toString()+".00";
    }
    if(xsd.length>1) {
      if (xsd[1].length < 2) {
        num = num.toString() + "0";
      }
    }
  }

  return num;
}

/**
 * 反解开EMOJI编码后的字符串 与上对应使用
 * @param str
 * @returns {*}
 */
export const uncodeUtf16 = function (str) {
  // eslint-disable-next-line no-useless-escape
  var reg = /\&#.*?;/g;
  var result = str.replace(reg, function (char) {
    var H, L, code;
    if (char.length == 9) {
      code = parseInt(char.match(/[0-9]+/g));
      H = Math.floor((code - 0x10000) / 0x400) + 0xD800;
      L = (code - 0x10000) % 0x400 + 0xDC00;
      return unescape("%u" + H.toString(16) + "%u" + L.toString(16));
    } else {
      return char;
    }
  });
  return result;
}



export const objInit = function (obj) {
  var keys = Object.getOwnPropertyNames(obj)
  for(var i = 0 ; i <keys.length ; i++){
    var key = keys[i]
    if(key == '__ob__') continue;
    obj[key] = ""
  }
}

export const backToHomePage = function () {
  clearAll()
  window.location.href = "#/login"
}

export const authButtons = function(buttons,authList){
  var notNullAuthList = authList || []
  var result = buttons.filter(({name})=>{
    return arrayContain(notNullAuthList,name);
  })
  return result
}

var baseValidateCancel = function (form) {
  form.fields.forEach(field => {
    field.validateState=""
  })
}
/**
 * 验证失败后一段时间，validate消失
 * @param form
 */
export const delayValidateCancel = function(form){
  setTimeout(()=>form.fields.forEach(field => {
    field.validateState=""
  }),3000)
}


export const validateCancelNow = function(form){
  if(form instanceof Array)    form.map((item)=>baseValidateCancel(item))
                        else    baseValidateCancel(form)
}

export const checkAuthList = function(authButtonsList,name){
    const checkAuthButtonsList = authButtonsList == undefined ? [] : authButtonsList
    for (var i = 0; i < checkAuthButtonsList.length; i++) {
        if (checkAuthButtonsList[i] == name) {
            return true
        }
    }
    return false
}

export const checkAndroid = function(){
    const u = navigator.userAgent;
    const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    return isAndroid
}