import axios from 'axios'
import {baseUrl} from '@/config/env'
import {ACCESS_TOKEN, REFRESH_TOKEN, EXPIRE_TIME } from './mutation-types'
import {responseUtil} from "@/libs/brightUtil";
import {backToHomePage} from "@/libs/brightUtil"
import {getToken, getRefreshToken, getExpireTime, setToken, localStorageSave} from '@/libs/util'

// 请求超时时间，10s
const REQUEST_TIME_OUT = 20 * 1000 * 3
// 成功码
const SUCCESS_CODE = 200
// 更换令牌的时间区间
const CHECK_REGION = 5 * 60 * 1000

const authorizationValue = 'Basic ZmViczoxMjM0NTY='

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: baseUrl,
    timeout: REQUEST_TIME_OUT, // 请求超时时间
    responseType: 'json',
    validateStatus(status) {
        return SUCCESS_CODE === status
    }
})

// 系统令牌刷新请求对象
const refresh_service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: REQUEST_TIME_OUT,
    responseType: 'json',
    validateStatus(status) {
        return SUCCESS_CODE === status
    }
})

const refresh = (url, params) => {
    params['grant_type'] = 'refresh_token'
    return refresh_service.post(url, params, {
        transformRequest: [(params) => {
            return tansParams(params)
        }],
        headers: {
            'Authorization': authorizationValue
        }
    })
}

// 异常拦截处理器
const errorHandler = (error) => {
    if (error.response) {
        const errorMessage = error.response.data === null ? '系统内部异常，请联系网站管理员' : (error.response.data.message || error.response.data)
        // 从 localstorage 获取 token
        const token = getToken()
        if (error.response.status === 401) {
            responseUtil.alertErrMsg('很抱歉，认证已失效，请重新登录')
            if (token) {
                backToHomePage()
            }
        } else if (error.response.status === 403) {
            responseUtil.alertErrMsg('很抱歉，您暂无该操作权限')
        } else if (error.response.status === 404) {
            responseUtil.alertErrMsg('很抱歉，资源未找到')
        } else {
            if (errorMessage === 'refresh token无效') {
                responseUtil.alertErrMsg('很抱歉，认证已失效，请重新登录')
                if (token) {
                    backToHomePage()
                }
            } else {
                if(error.response.config.data.indexOf("checkCode=false") < 0){
                    responseUtil.alertErrMsg(errorMessage)
                }
            }
        }
    }
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
    if (getToken()) {
        config.headers['Authorization'] = 'bearer ' + getToken()
    }
    return config
}, errorHandler)

request.interceptors.request.use(config => {
        let _config = config
        try {
            const expireTime = getExpireTime()
            if (expireTime) {
                const left = expireTime - new Date().getTime()
                const refreshToken = getRefreshToken()
                if (left < CHECK_REGION && refreshToken) {
                    _config = queryRefreshToken(_config, refreshToken)
                } else {
                    if (getToken()) {
                        _config.headers['Authorization'] = 'bearer ' + getToken()
                    }
                }
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
        }
        return _config
    },
    error => {
        // eslint-disable-next-line no-console
        console.log(error)
        return Promise.reject(error)
    }
)

// response interceptor
request.interceptors.response.use((response) => {
    if (response.request.responseType === 'blob') {
        return response
    } else {
        return response.data
    }
}, errorHandler)

async function queryRefreshToken(config, refreshToken) {
    const result = await refresh('auth/oauth/token', {
        refresh_token: refreshToken
    })
    if (SUCCESS_CODE === result.status) {
        saveData(result.data)
        config.headers['Authorization'] = 'bearer ' + getToken()
    }
    return config
}

function saveData(data) {
    setToken(data.access_token)
    localStorageSave(REFRESH_TOKEN,data.refresh_token)
    const current = new Date()
    const expireTime = current.setTime(current.getTime() + 1000 * data.expires_in)
    localStorageSave(EXPIRE_TIME, expireTime)
}

export default request

function tansParams(params) {
    let result = ''
    Object.keys(params).forEach((key) => {
        if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
        }
    })
    return result
}

const CONTENT_TYPE = {
    URL_ENCODED: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

export {
    request as axios,
    tansParams,
    CONTENT_TYPE
}

