// cookie保存的天数
import { ACCESS_TOKEN, REFRESH_TOKEN, EXPIRE_TIME, USER } from '@/getData/mutation-types'
import { authCheckToken } from '@/getData/getData'


export const setToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN, JSON.stringify(token))
}

export const getToken = () => {
  const token = JSON.parse(localStorage.getItem(ACCESS_TOKEN))
  if (token) return token
  else return false
}

export const setUserDetails = (value) => {
  localStorage.setItem(USER, JSON.stringify(value))
}

export const getUserDetails = (value) => {
  const user = JSON.parse(localStorage.getItem(value))
  if (user) return user
  else return false
}

export function getRefreshToken () {
  return JSON.parse(localStorage.getItem(REFRESH_TOKEN, ''))
}

export function getExpireTime () {
  return JSON.parse(localStorage.getItem(EXPIRE_TIME, 0))
}

export function clearAll () {
  localStorage.clear()
}

export function localStorageSave (key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

export function localStorageGet (key) {
  const value = localStorage.getItem(key)
  if(value){
    return JSON.parse(value)
  } else {
    return {}
  }
}

export async function checkToken() {
  let retType = false
  if (getToken() && getRefreshToken() && getExpireTime()) {
    retType = true
    retType = await checkAuthToken()
  }
  return retType
}

export async function checkAuthToken() {
  let type = true
  await authCheckToken().then((res) => {
    type = !!res.active
  }).catch(() => {
    type = false
  })
  return type
}

export function randomNum (len, radix) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  const uuid = []
  radix = radix || chars.length

  if (len) {
    // Compact form
    // eslint-disable-next-line standard/computed-property-even-spacing
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix ]
  } else {
    // rfc4122, version 4 form
    let r

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16
        uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  return uuid.join('') + new Date().getTime()
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/[T]/.test(time)) {
        time = time.replaceAll('T', ' ')
      }

      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  // eslint-disable-next-line camelcase
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    // eslint-disable-next-line standard/computed-property-even-spacing
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  // eslint-disable-next-line camelcase
  return time_str
}


export function getUrlKey(name){//获取url 参数
  // eslint-disable-next-line no-sparse-arrays
  return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
}

export function getCodeApi(state){//获取code
  let urlNow=encodeURIComponent(window.location.href);
  let scope='snsapi_base'; //snsapi_userinfo //静默授权 用户无感知
  let appid='wx3c00e1f5ef0967b0';
  let url=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
  window.location.replace(url);
}

export function isWeiXin () {
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf('micromessenger') > -1) {
    return true; // 是微信端
  } else {
    return false;
  }
}
