/* --------接口的地址-------------------------- */
// let baseUrl = 'http://127.0.0.1:7100/'
// let baseUrl = 'http://192.168.5.229:8000/'
let baseUrl = 'https://hr.gateway.bh-talent.com/'
/* ------------授权服务器的地址----------------------------- */
let authUrl = baseUrl + '/auth/captcha'
// let authUrl = 'https://hr.gateway.bh-talent.com/auth/captcha'
/* ------------参数是否加密----------------------------- */
let encodeFlag = false
export {
  baseUrl,
  encodeFlag,
  authUrl
}