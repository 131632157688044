export const ACCESS_TOKEN = 'Access-Token'
export const REFRESH_TOKEN = 'refreshToken'
export const EXPIRE_TIME = 'expireTime'
export const USER = 'user'
export const DICTIONARY = 'dictionary'
export const ALLUSERDICTIONARY = 'allUserDictionary'
export const USERDICTIONARY = 'userDictionary'
export const DEPTTREE = 'deptTree'
export const ROLEDICT = 'roleDict'

