import request from './request'
import requestUtil from './requestUtil'
import {getToken} from "@/libs/util";
// import { encryption } from './encryption'

let UrlFactory = {
  login: '/auth/oauth/token',
  UserInfo: '/auth/user',
  CheckToken: '/auth/oauth/check_token',
  UserMenu: '/system/menu/antDesign/',
  getImgCode: '/auth/captcha'
}

export function login (parameter) {
  return request({
    url: UrlFactory.login,
    method: 'POST',
    data: tansParams(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ZmViczoxMjM0NTY='
    }
  })
}

function tansParams (params) {
  let result = ''
  Object.keys(params).forEach((key) => {
    if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
      result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
    }
  })
  return result
}

export function getImgCode (parameter) {
  return request({
    url: UrlFactory.getImgCode + '?key=' + parameter,
    method: 'GET',
    responseType: 'arraybuffer'
  })
}

export function getUserInfo () {
  return request({
    url: UrlFactory.UserInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getCurrentUserNav (username, parameter) {
  return request({
    url: UrlFactory.UserMenu + username,
    method: 'get',
    params: parameter
  })
}

export function authCheckToken () {
  return request({
    url: UrlFactory.CheckToken + '?token=' + getToken(),
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ZmViczoxMjM0NTY='
    }
  })
}

export function myApproveList(param) {
  return requestUtil.get('/position/offerExtension/approveList', param)
}

export function initOfferExtension(data) {
  return requestUtil.postBody('/position/offerExtension/init', data)
}

export function queryEnclosure (data) {
  return requestUtil.postBody('/personnel/personnel/queryEnclosure', data)
}

export function getBaseInfo(personnelId) {
  return requestUtil.get('/personnel/personnel/getBaseInfo/' + personnelId)
}

export function calculationOfferExtension(data) {
  return requestUtil.postBody('/position/offerExtension/calculation', data)
}

export function calculationOfferExtensionFreelance(data) {
  return requestUtil.postBody('/position/offerExtension/calculationFreelance', data)
}

export function saveOfferExtension(data) {
  return requestUtil.postBody('/position/offerExtension/save/', data)
}

export function myApprove(param) {
  return requestUtil.postBody('/position/offerExtension/approve', param)
}

export function getOpenId(param) {
  return requestUtil.postBody('/personnel/wechat/getOpenId', param)
}

export function bindUser(param) {
  return requestUtil.postBody('/personnel/wechat/bindUser', param)
}

export function initClueDetail(id) {
  return requestUtil.get(`/crm/clue/approveDetail/${id}`)
}

export function passAudit(id) {
  return request.get(`/crm/approve/passAudit/${id}`)
}

export function clueTransfer (id) {
  return request.get(`/crm/approve/clueTransfer/${id}`)
}

export function overrule(data) {
  return requestUtil.postBody('/crm/approve/overrule', data)
}

export function znPositionApprovePass(data) {
  return requestUtil.postBody(`/zn/position/approvePass`, data)
}

export function znPositionApproveReject(data) {
  return requestUtil.postBody(`/zn/position/approveReject`, data)
}

export function znPositionApproveDetail(id) {
  return requestUtil.get(`/zn/position/approveView`, {business_id: id})
}
