import request from './request'

const authorizationValue = 'Basic ZmViczoxMjM0NTY='

const CONTENT_TYPE = {
  URL_ENCODED: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

const requestUtil = {
  login(url, params) {
    params['grant_type'] = 'password'
    return request.post(url, params, {
      transformRequest: [(params) => {
        return tansParams(params)
      }],
      headers: {
        'Authorization': authorizationValue
      }
    })
  },
  post(url, params) {
    return request.post(url, params, {
      transformRequest: [(params) => {
        return tansParams(params)
      }],
      headers: CONTENT_TYPE.URL_ENCODED
    })
  },
  postBody(url, data) {
    return request.post(url, data, {
      headers: {
        'Content-Type': 'application/json;;charset:utf-8'
      }
    })
  },
  putParams(url, params) {
    return request.put(url, params, {
      transformRequest: [(params) => {
        return tansParams(params)
      }],
      headers: CONTENT_TYPE.URL_ENCODED
    })
  },
  put(url, params) {
    return request.put(url, params, {
      headers: {
        'Content-Type': 'application/json;;charset:utf-8'
      }
    })
  },
  get(url, params) {
    const transformedParams = transformParams(params)
    return request.get(`${url}${transformedParams}`)
  },
  delete(url, params) {
    const transformedParams = transformParams(params)
    return request.delete(`${url}${transformedParams}`)
  },
  upload(url, params) {
    return request.post(url, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  download(url, params, filename) {
    return request.post(url, params, {
      transformRequest: [(params) => {
        return tansParams(params)
      }],
      responseType: 'blob'
    }).then((r) => {
      const fileName = window.decodeURI(r.headers['content-disposition'].split('=')[1], 'UTF-8')
      const blob = r.data
      if ('download' in document.createElement('a')) {
        const eLink = document.createElement('a')
        eLink.download = fileName
        eLink.style.display = 'none'
        eLink.href = URL.createObjectURL(blob)
        document.body.appendChild(eLink)
        eLink.click()
        URL.revokeObjectURL(eLink.href)
        document.body.removeChild(eLink)
      } else {
        navigator.msSaveBlob(blob, filename)
      }
    }).catch((r) => {
      console.error(r)
    })
  }
}

function transformParams (params) {
  let _params
  if (Object.is(params, undefined)) {
    _params = ''
  } else {
    _params = '?'
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key) && !Object.is(params[key], null)) {
        _params += `${key}=${params[key]}&`
      }
    }
  }
  return _params
}

function tansParams(params) {
  let result = ''
  Object.keys(params).forEach((key) => {
    if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
      result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
    }
  })
  return result
}

export default requestUtil
